<div class="appointment-invitation-container">
  @if (titleVisible) {
  <span class="appointment-invitation-info">{{ title }}</span>
  }
  <div class="chat-bubble tri-right btm-left round">
    <div class="chat-bubble-text">
      <div class="chat-loading-spinner" *ngIf="isLoading(); else text">
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <ng-template #text>
        <p class="text" [innerHtml]="invitationText()"></p>
      </ng-template>
    </div>
  </div>
</div>

import { LegalEntityDto } from '../../../api/gen';
import { LegalEntityAddress } from './legal-entity-address';

export class LegalEntity {
  id?: string;
  displayName?: string | null;
  logoUrl?: string | null;
  isDefault?: boolean;
  address?: LegalEntityAddress;

  public static fromDto(dto: LegalEntityDto): LegalEntity {
    return {
      id: dto.id,
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
      isDefault: dto.isDefault,
      address: dto.address ? LegalEntityAddress.fromDto(dto.address) : null,
    } as LegalEntity;
  }
}

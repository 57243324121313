import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { SchedulingRoutingModule } from './scheduling-routing.module';
import { SchedulingComponent } from './scheduling.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { NewEventDialogComponent } from './new-event-dialog/new-event-dialog.component';

import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InquiryDetailsComponent } from './inquiry-details/inquiry-details.component';

import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { AppointmentDateComponent } from './appointment-date/appointment-date.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import { StatusTagComponent } from './status-tag/status-tag.component';

import { ClipboardModule } from '@angular/cdk/clipboard';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AttachmentPreviewComponent } from './inquiry-details/attachments/attachment-preview/attachment-preview.component';
import { AttachmentComponent } from './inquiry-details/attachments/attachment/attachment.component';
import { InquiryDetailsAttachmentsComponent } from './inquiry-details/inquiry-details-attachments/inquiry-details-attachments.component';
import { InquiryDetailsCallDataComponent } from './inquiry-details/inquiry-details-call-data/inquiry-details-call-data.component';
import { InquiryDetailsCustomerDataComponent } from './inquiry-details/inquiry-details-customer-data/inquiry-details-customer-data.component';
import { InquiryDetailsReportComponent } from './inquiry-details/inquiry-details-report/inquiry-details-report.component';
import { InquiryDetailsTimelineComponent } from './inquiry-details/inquiry-details-timeline/inquiry-details-timeline.component';
import { StartAppointmentComponent } from './start-appointment/start-appointment.component';
import { LatestCallsComponent } from './latest-calls/latest-calls.component';
import { CallHistoryOverviewComponent } from './call-history-overview/call-history-overview.component';
import { ViewAttachmentDialogComponent } from './dialogs/view-attachment-dialog/view-attachment-dialog.component';
import { MatRippleModule } from '@angular/material/core';
import { RedialDialogComponent } from './dialogs/redial-dialog/redial-dialog.component';
import { ConfirmDeleteInquiryDialogComponent } from './dialogs/confirm-delete-inquiry-dialog/confirm-delete-inquiry-dialog.component';
import { InquiryDetailsDeletionDataComponent } from './inquiry-details/inquiry-details-deletion-data/inquiry-details-deletion-data.component';
import { NotAllowedToMakeCallComponent } from './dialogs/not-allowed-to-make-call/not-allowed-to-make-call.component';
import { InquiryDetailsDistanceComponent } from './inquiry-details/inquiry-details-distance/inquiry-details-distance.component';
import { RenameReportDialogComponent } from './inquiry-details/inquiry-details-report/rename-report-dialog/rename-report-dialog.component';
import { InquiryDetailsHeaderComponent } from './inquiry-details/inquiry-details-header/inquiry-details-header.component';
import { CreateCustomReportDialogComponent } from './inquiry-details/inquiry-details-report/create-custom-report-dialog/create-custom-report-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AppointmentInvitationTextComponent } from './appointment-invitation-text/appointment-invitation-text.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { PhoneNumberNotValidDialogComponent } from './dialogs/phone-number-not-valid-dialog/phone-number-not-valid-dialog.component';
import { InquiryDetailsChatComponent } from './inquiry-details/inquiry-details-chat/inquiry-details-chat.component';
import { ChatModule } from '@mad-tech/aidar-live-chat';
import { ViewAttachmentImageComponent } from './dialogs/view-attachment-image/view-attachment-image.component';
import { StartAppointmentExpansionPanelComponent } from './start-appointment-expansion-panel/start-appointment-expansion-panel.component';
import { StartAppointmentWithoutInvitationComponent } from './start-appointment-without-invitation/start-appointment-without-invitation.component';
import { InvitationPreviewComponent } from '../shared/invitation-preview/invitation-preview.component';

@NgModule({
  declarations: [
    SchedulingComponent,
    NewEventDialogComponent,
    DashboardComponent,
    InquiryDetailsComponent,
    AttachmentComponent,
    AttachmentPreviewComponent,
    ScheduleDialogComponent,
    StatusTagComponent,
    DateRangeComponent,
    AppointmentDateComponent,
    StartAppointmentComponent,
    InquiryDetailsReportComponent,
    InquiryDetailsTimelineComponent,
    InquiryDetailsAttachmentsComponent,
    InquiryDetailsCallDataComponent,
    InquiryDetailsCustomerDataComponent,
    LatestCallsComponent,
    CallHistoryOverviewComponent,
    ViewAttachmentDialogComponent,
    RedialDialogComponent,
    ConfirmDeleteInquiryDialogComponent,
    InquiryDetailsDeletionDataComponent,
    NotAllowedToMakeCallComponent,
    InquiryDetailsDistanceComponent,
    RenameReportDialogComponent,
    CreateCustomReportDialogComponent,
    InquiryDetailsHeaderComponent,
    AppointmentInvitationTextComponent,
    PhoneNumberNotValidDialogComponent,
    InquiryDetailsChatComponent,
    ViewAttachmentImageComponent,
    StartAppointmentExpansionPanelComponent,
  ],
  imports: [
    CommonModule,
    SchedulingRoutingModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    // if we ever need a calendar, angular-calendar is cool:
    /* CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }), */
    MatIconModule,
    DragAndDropModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatSelectCountryModule,
    MatTooltipModule,
    ClipboardModule,
    MatProgressBarModule,
    MatRippleModule,
    NgOptimizedImage,
    MatBadgeModule,
    MatButtonToggleModule,
    MatExpansionModule,
    ChatModule,
    StartAppointmentWithoutInvitationComponent,
    InvitationPreviewComponent,
  ],
  exports: [DateRangeComponent],
})
export class SchedulingModule {}

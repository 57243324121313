import { LegalEntityAddressDto } from '../../../api/gen';

export class LegalEntityAddress {
  firstAddressLine?: string | null;
  secondAddressLine?: string | null;
  locality?: string | null;
  postCode?: string | null;
  region?: string | null;
  countryCode?: string | null;

  public static fromDto(dto: LegalEntityAddressDto): LegalEntityAddress {
    return {
      firstAddressLine: dto.firstAddressLine,
      secondAddressLine: dto.secondAddressLine,
      locality: dto.locality,
      postCode: dto.postCode,
      region: dto.region,
      countryCode: dto.countryCode,
    } as LegalEntityAddress;
  }
}

<div class="container">
  @if (isLoading$()) {
  <mat-spinner class="loading-spinner" diameter="30"></mat-spinner>
  }
  <button
    mat-flat-button
    color="primary"
    (click)="onStartAppointmentWithoutInvitationClicked()"
    [disabled]="isLoading$()"
    i18n
  >
    Sofortbesprechung starten
  </button>
</div>

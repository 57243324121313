import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  isDevMode,
} from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule } from 'keycloak-angular';
import { ApiModule, Configuration, ConfigurationParameters } from '../api/gen';
import { environment } from '../environments/environment';
import { interceptorProviders } from './interceptors';
import { JoinOpenAppointmentComponent } from './menu/join-open-appointment/join-open-appointment.component';
import { MenuBarComponent } from './menu/menu-bar/menu-bar.component';
import { ProfileComponent } from './profile/profile.component';
import { SchedulingModule } from './scheduling/scheduling.module';
import { VideoAppointmentModule } from './video-appointment/video-appointment.module';

import { NgOptimizedImage } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { GestureConfig } from './init/gesture-config';
import { SetupPlatformOverviewComponent } from './setup/setup-platform-overview/setup-platform-overview.component';
import { SharedModule } from './shared/shared.module';
import { SignupInvitationLandingComponent } from './signup/signup-invitation-landing/signup-invitation-landing.component';

import 'hammerjs';
import { TrialExpiredDialogComponent } from './subscription/trial-expired-dialog/trial-expired-dialog.component';
import { QuotaDisplayComponent } from './menu/quota-display/quota-display.component';
import { ManageSubscriptionComponent } from './subscription/manage-subscription/manage-subscription.component';
import { NotificationBarComponent } from './menu/notification-bar/notification-bar.component';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { PersonalInformationContactComponent } from './profile/personal-information-contact/personal-information-contact.component';
import { EditContactDialogComponent } from './profile/dialogs/edit-contact-dialog/edit-contact-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { PersonalInformationAuthenticationComponent } from './profile/personal-information-authentication/personal-information-authentication.component';
import { PersonalInformationDepartmentComponent } from './profile/personal-information-department/personal-information-department.component';
import { EditDepartmentDialogComponent } from './profile/dialogs/edit-department-dialog/edit-department-dialog.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';

import * as Sentry from '@sentry/angular-ivy';
import { HelperChatService } from './chat/helper-chat-service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationCenterComponent } from './menu/notification-center/notification-center.component';
import { NotificationItemComponent } from './menu/notification-center/notification-item/notification-item.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatBadge } from '@angular/material/badge';
import { PersonalInformationLegalEntityComponent } from './profile/personal-information-legal-entity/personal-information-legal-entity.component';
import { EditLegalEntityDialogComponent } from './profile/dialogs/edit-legal-entity-dialog/edit-legal-entity-dialog.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SideNavComponent } from './menu/side-nav/side-nav.component';
import { BrandingColorService } from './services/api/branding-color.service';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiPath,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuBarComponent,
    ProfileComponent,
    JoinOpenAppointmentComponent,
    SignupInvitationLandingComponent,
    SetupPlatformOverviewComponent,
    TrialExpiredDialogComponent,
    QuotaDisplayComponent,
    ManageSubscriptionComponent,
    NotificationBarComponent,
    PersonalInformationComponent,
    PersonalInformationContactComponent,
    PersonalInformationAuthenticationComponent,
    PersonalInformationDepartmentComponent,
    PersonalInformationLegalEntityComponent,
    EditContactDialogComponent,
    EditDepartmentDialogComponent,
    EditLegalEntityDialogComponent,
    LoginComponent,
    AuthCallbackComponent,
    NotificationCenterComponent,
    NotificationItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatButtonModule,
    FormsModule,
    MatNativeDateModule,
    MatCheckboxModule,
    HttpClientModule,
    VideoAppointmentModule,
    MatSidenavModule,
    KeycloakAngularModule,
    ApiModule.forRoot(apiConfigFactory),
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatSnackBarModule,
    MatSelectCountryModule.forRoot('de'),
    MatDialogModule,
    MatTableModule,
    SchedulingModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    MatStepperModule,
    HammerModule,
    MatOptionModule,
    MatSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatTooltip,
    MatBadge,
    MatExpansionPanel,
    SideNavComponent,
  ],
  providers: [
    {
      provide: 'ChatService',
      useClass: HelperChatService,
    },
    {
      provide: 'BrandingColorApi',
      useClass: BrandingColorService,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    interceptorProviders,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig,
    },
  ],
  bootstrap: [AppComponent],
  exports: [MenuBarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

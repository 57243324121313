<div class="create-custom-report-container">
  <h1 mat-dialog-title i18n>Benutzerdefinierten Report erstellen</h1>
  <div mat-dialog-content class="create-custom-report-content-container">
    <div *ngIf="(isLoading$ | async) === false; else loadingSpinner">
      <mat-form-field>
        <mat-label i18n>Sprache</mat-label>
        <mat-select [(value)]="selectedStaticTemplate">
          @for (template of templates(); track template.id) {
          <mat-option [value]="template.id">{{
            template.cultureDisplayName
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="report-checkboxes">
        <section class="report-section-checkboxes">
          <mat-checkbox i18n [(ngModel)]="headingChecked"
            >Kopfzeile</mat-checkbox
          >
          <mat-checkbox i18n [(ngModel)]="companySettingsChecked"
            >Firmeninfo</mat-checkbox
          >
          <mat-checkbox i18n [(ngModel)]="inquiryDetailsChecked"
            >Details zur Anfrage</mat-checkbox
          >
          <mat-checkbox i18n [(ngModel)]="notesChecked">Notizen</mat-checkbox>
        </section>
        <section class="report-section-checkboxes">
          <mat-checkbox
            class="example-margin"
            [checked]="allSelected"
            [indeterminate]="someSelected()"
            (change)="setAll($event.checked)"
          >
            Anhänge
          </mat-checkbox>
          <div class="report-section-checkboxes-children">
            <div
              *ngFor="
                let subtask of attachmentCheckBox.attachments;
                trackBy: attachmentTrackBy
              "
            >
              <mat-checkbox
                [(ngModel)]="subtask.selected"
                (ngModelChange)="updateAllSelected()"
              >
                <div class="attachment-checkbox-container">
                  <img
                    class="attachment-checkbox-image"
                    src="{{ subtask.thumbnail }}"
                    width="30"
                    height="30"
                    i18n-alt
                    alt="Miniaturansicht des Anhangs"
                  />
                  <span>{{ subtask.name }}</span>
                </div>
              </mat-checkbox>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ng-template #loadingSpinner>
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions class="dialog-actions-container">
    <button mat-button mat-dialog-close color="warn" i18n>Abbrechen</button>
    <button
      mat-button
      color="primary"
      (click)="createCustomReport()"
      [disabled]="isLoading$ | async"
      i18n
    >
      Erstellen
    </button>
  </div>
</div>

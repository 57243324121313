<div class="container">
  <div class="new-inquiry">
    <div class="start-appointment-container">
      <app-start-appointment-expansion-panel></app-start-appointment-expansion-panel>
    </div>
  </div>

  <div class="history">
    <mat-card>
      <mat-card-content>
        <app-call-history-overview></app-call-history-overview>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { BackendRole } from '../../authentication/backend-role';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import {
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatNavList,
} from '@angular/material/list';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { RoleService } from '../../authentication/role.service';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { isScreenWithoutMenus } from '../menu-visibility-helper';
import { InstallPwaComponent } from './install-pwa/install-pwa.component';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    MatDivider,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatSidenavContent,
    RouterOutlet,
    MatNavList,
    MatSidenavContainer,
    MatSidenav,
    RouterLinkActive,
    RouterLink,
    NgIf,
    InstallPwaComponent,
    MatListItemTitle,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatRipple,
  ],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  @Output() activatedComponentChanged = new EventEmitter<any>();
  showSideNav = false;
  protected readonly BackendRole = BackendRole;

  constructor(
    public router: Router,
    private readonly route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    protected readonly roleService: RoleService
  ) {}

  public onRouterOutletActivate(event: any) {
    this.processActivatedComponent(event);
    this.processQueryParameters();
    this.cdr.detectChanges();
  }

  private processActivatedComponent(event: any) {
    this.activatedComponentChanged.emit(event);
    this.showSideNav = !isScreenWithoutMenus(event);
  }

  private processQueryParameters() {
    if (this.route.snapshot.queryParams['hideSideNav'] === 'true') {
      this.showSideNav = false;
    }
  }
}

<mat-accordion>
  <mat-expansion-panel
    expanded="true"
    (opened)="onSmsLinkPanelOpened()"
    hideToggle="true"
  >
    <mat-expansion-panel-header
      [ngClass]="isSmsPanelOpened ? 'no-events' : 'default'"
    >
      <mat-panel-title i18n> SMS Einladung </mat-panel-title>
      <mat-panel-description i18n>
        Senden Sie Ihre Einladung einfach per SMS
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-start-appointment></app-start-appointment>
    <app-appointment-invitation-text></app-appointment-invitation-text>
  </mat-expansion-panel>
  <mat-expansion-panel
    (opened)="onInvitationLinkPanelOpened()"
    hideToggle="true"
  >
    <mat-expansion-panel-header
      [ngClass]="isInvitationLinkPanelOpen ? 'no-events' : 'default'"
    >
      <mat-panel-title i18n>Einladungslink </mat-panel-title>
      <mat-panel-description i18n>
        Sofortbesprechung starten und Link später teilen
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-start-appointment-without-invitation></app-start-appointment-without-invitation>
  </mat-expansion-panel>
</mat-accordion>

import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { TinyColor } from '@ctrl/tinycolor';
import { BrandingColorApiInterface } from './branding-color-api-interface';
import { BrandingColorResult } from './branding-color-result';
import { BrandingColor } from './branding-color';

@Injectable({ providedIn: 'root' })
export class BrandingColorDataService {
  defaultPrimaryColor = '#1976d2';
  defaultAccentColor = '#d27619';
  defaultWarnColor = '#f44336';

  constructor(
    @Inject('BrandingColorApi')
    private readonly brandingColorService: BrandingColorApiInterface
  ) {}

  public applyBrandingColor(): Observable<BrandingColorResult> {
    return this.brandingColorService.getActiveBrandingColor().pipe(
      switchMap((brandingColor: BrandingColor) => {
        this.updateColorValues(
          brandingColor.primaryColorHex,
          brandingColor.accentColorHex,
          brandingColor.warnColorHex
        );
        return of({
          success: true,
          usedDefaultColors: false,
        } as BrandingColorResult);
      }),
      catchError(() => {
        this.updateColorValues();
        return of({
          success: true,
          usedDefaultColors: true,
        } as BrandingColorResult);
      })
    );
  }

  private updateColorValues(
    primaryColor?: string,
    accentColor?: string,
    warnColor?: string
  ) {
    const dynamicPrimaryColorPalette = this.computePrimaryThemePalette(
      primaryColor || this.defaultPrimaryColor,
      accentColor || this.defaultAccentColor
    );
    this.updatePrimaryThemePalette(dynamicPrimaryColorPalette);

    const dynamicWarnColorPalette = this.computeWarnThemePalette(
      warnColor || this.defaultWarnColor
    );
    this.updateWarnThemePalette(dynamicWarnColorPalette);
  }

  private updatePrimaryThemePalette(colors: Color[]) {
    colors.forEach((color) => {
      document.documentElement.style.setProperty(
        `--theme-dynamic-palette-${color.name}`,
        color.hex
      );
      document.documentElement.style.setProperty(
        `--theme-dynamic-palette-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  private updateWarnThemePalette(colors: Color[]) {
    colors.forEach((color) => {
      document.documentElement.style.setProperty(
        `--theme-dynamic-warn-palette-${color.name}`,
        color.hex
      );
      document.documentElement.style.setProperty(
        `--theme-dynamic-warn-palette-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  private computeWarnThemePalette(warnHex: string) {
    return this.computePrimaryThemePalette(warnHex, warnHex);
  }

  private computePrimaryThemePalette(
    primaryHex: string,
    accentHex: string
  ): Color[] {
    return [
      this.getColorObject(new TinyColor(primaryHex).lighten(52), '50'),
      this.getColorObject(new TinyColor(primaryHex).lighten(37), '100'),
      this.getColorObject(new TinyColor(primaryHex).lighten(26), '200'),
      this.getColorObject(new TinyColor(primaryHex).lighten(12), '300'),
      this.getColorObject(new TinyColor(primaryHex).lighten(6), '400'),
      this.getColorObject(new TinyColor(primaryHex), '500'), // Default
      this.getColorObject(new TinyColor(primaryHex).darken(6), '600'),
      this.getColorObject(new TinyColor(primaryHex).darken(12), '700'),
      this.getColorObject(new TinyColor(primaryHex).darken(18), '800'),
      this.getColorObject(new TinyColor(primaryHex).darken(24), '900'),
      this.getColorObject(new TinyColor(accentHex).lighten(50), 'A100'),
      this.getColorObject(new TinyColor(accentHex), 'A200'), // Default
      this.getColorObject(new TinyColor(accentHex).darken(20), 'A400'),
      this.getColorObject(new TinyColor(accentHex).darken(50), 'A700'),
    ];
  }

  private getColorObject(value: TinyColor, name: string): Color {
    const c = new TinyColor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }
}

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

<div class="container">
  <app-personal-information-contact
    [employee]="employee"
  ></app-personal-information-contact>

  <app-personal-information-department [employee]="employee">
  </app-personal-information-department>

  <app-personal-information-legal-entity
    [employee]="employee"
  ></app-personal-information-legal-entity>

  <app-personal-information-authentication
    [employee]="employee"
  ></app-personal-information-authentication>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { InitializationStatusService } from '../../../api/gen';
import { map } from 'rxjs/operators';
import { InitializationStatus } from '../../model/initialization-status';
import { routes } from '../../app-routing.module';
import { RoleService } from '../role.service';
import { BackendRole } from '../backend-role';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly roleService: RoleService,
    private readonly initializationStatusService: InitializationStatusService
  ) {}

  async ngOnInit() {
    try {
      await this.authService.authCallback();
    } catch (error) {
      this.router.navigate(['/login']);
    }

    if (this.roleService.hasRole(BackendRole.Admin)) {
      this.checkInitializationStatus();
    } else {
      this.navigateToHome();
    }
  }

  private checkInitializationStatus() {
    this.initializationStatusService
      .initializationGet()
      .pipe(map((dto) => InitializationStatus.fromDto(dto)))
      .subscribe((initializationStatus) => {
        if (
          initializationStatus.companySettingsInitialized === false ||
          initializationStatus.inquiryReportTemplateInitialized === false
        ) {
          routes.find((r) => r.path === '').redirectTo = 'setup';
          this.router.resetConfig(routes);
          this.router.navigate(['']);
        } else {
          this.navigateToHome();
        }
      });
  }

  private navigateToHome() {
    this.authService.getUser().then(() => {
      this.router.navigate(['scheduling']);
    });
  }
}

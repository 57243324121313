import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LegalEntity } from '../../model/legal-entity/legal-entity';
import { LegalEntityService } from '../../services/api/legal-entity.service';
import { MatDialog } from '@angular/material/dialog';
import { Employee } from '../../model/employee/employee';
import { EditLegalEntityDialogComponent } from '../dialogs/edit-legal-entity-dialog/edit-legal-entity-dialog.component';

@Component({
  selector: 'app-personal-information-legal-entity',
  templateUrl: './personal-information-legal-entity.component.html',
  styleUrls: ['./personal-information-legal-entity.component.scss'],
  providers: [LegalEntityService],
})
export class PersonalInformationLegalEntityComponent implements OnInit {
  @Input() employee: Employee;

  protected readonly legalEntity$ = new BehaviorSubject<LegalEntity>(null);

  constructor(
    private readonly legalEntityService: LegalEntityService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.updateLegalEntity();
  }

  onChangeLegalEntity() {
    const dialogRef = this.dialog.open(EditLegalEntityDialogComponent, {
      data: this.employee,
      minWidth: '25rem',
    });

    dialogRef.afterClosed().subscribe((result: Employee) => {
      if (result) {
        this.employee = result;
        this.updateLegalEntity();
      }
    });
  }

  private updateLegalEntity() {
    if (this.employee.legalEntityId) {
      this.legalEntityService
        .getLegalEntity(this.employee.legalEntityId)
        .subscribe((legalEntity) => this.legalEntity$.next(legalEntity));
    }
  }
}

import { ReportService as ReportApi } from 'projects/helper-client/src/api/gen';
import { map, Observable } from 'rxjs';
import { Report } from '../../model/report/report';
import { CreateInquirySummaryReportRequest } from '../../model/report/request/create-inquiry-summary-report-request';
import { Injectable } from '@angular/core';
import { ReportMailResult } from '../../model/report/report-mail-result';

@Injectable()
export class ReportService {
  constructor(private readonly reportService: ReportApi) {}

  getReports(inquiryIdentifier: string): Observable<Report[]> {
    return this.reportService
      .reportInquiryInquiryIdGet(inquiryIdentifier)
      .pipe(map((x) => x.map((dto) => Report.fromDto(dto))));
  }

  createInquirySummaryReport(
    request: CreateInquirySummaryReportRequest
  ): Observable<Report> {
    return this.reportService
      .reportInquiryPost(CreateInquirySummaryReportRequest.toDto(request))
      .pipe(map((dto) => Report.fromDto(dto)));
  }

  deleteReport(reportId: string): Observable<void> {
    return this.reportService.reportReportIdDelete(reportId);
  }

  updateReportName(reportId: string, fileName: string): Observable<Report> {
    return this.reportService
      .reportReportIdPut(reportId, fileName)
      .pipe(map((dto) => Report.fromDto(dto)));
  }

  sendReportToUser(reportId: string): Observable<ReportMailResult> {
    return this.reportService
      .reportReportIdEmailPost(reportId)
      .pipe(map((dto) => ReportMailResult.fromDto(dto)));
  }
}

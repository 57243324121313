<h2 mat-dialog-title i18n>Standort ändern</h2>
<mat-dialog-content>
  <form [formGroup]="legalEntityForm" class="form-container">
    <mat-form-field class="legal-entity-form">
      <mat-label i18n>Standort</mat-label>
      <mat-select formControlName="legalEntity">
        <mat-option
          *ngFor="let legalEntity of legalEntities$ | async"
          [value]="legalEntity.id"
        >
          {{ legalEntity.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    <span i18n>Abbrechen</span>
  </button>
  <button color="primary" mat-raised-button (click)="onClickSaveLegalEntity()">
    <mat-icon>save</mat-icon>
    <span i18n>Speichern</span>
  </button>
</mat-dialog-actions>

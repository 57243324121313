import { Component, signal } from '@angular/core';
import { CompanySettingsService } from '../../services/api/company.service';
import { InvitationDataService } from '../services/invitation-data.service';
import {
  Invitation,
  InvitationLanguage,
  InvitationMethod,
  InvitationType,
} from '../../model/inquiry/invitation';
import { RealmService } from '../../authentication/realm.service';
import { BrandingInvitationService } from '../../services/api/branding-invitation.service';
import { BrandingInvitation } from '../../model/company/branding/branding-invitation';
import { map } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-appointment-invitation-text',
  templateUrl: './appointment-invitation-text.component.html',
  styleUrls: ['./appointment-invitation-text.component.scss'],
  providers: [BrandingInvitationService],
})
export class AppointmentInvitationTextComponent {
  private readonly selectedInvitationTemplateIdStorageKey =
    'blankmile-appointment-invitation-template-id';

  protected isLoading = signal(true);
  protected invitationText = signal('');
  protected panelOpenState: boolean = false;
  protected companyDisplayName: string;
  private readonly realm: string = 'madtech';

  protected selectedInvitationTemplate: InvitationTemplate;
  protected selectedInvitationTemplateId: string;
  protected invitationTemplates = signal<InvitationTemplate[]>([]);

  constructor(
    private readonly companyService: CompanySettingsService,
    private readonly invitationDataService: InvitationDataService,
    private readonly brandingInvitationService: BrandingInvitationService,
    private readonly realmService: RealmService
  ) {
    this.selectedInvitationTemplateId =
      localStorage.getItem(this.selectedInvitationTemplateIdStorageKey) ??
      'default-template-german';

    this.companyService.getCompanySettings().subscribe((company) => {
      this.companyDisplayName = company.displayName;
      this.defaultTextEnglish.set(
        this.getDefaultTextEnglish(this.companyDisplayName)
      );
      this.defaultTextGerman.set(
        this.getDefaultTextGerman(this.companyDisplayName)
      );
      this.updateBrandingInvitations();
    });
    this.realm = this.realmService.getRealm();
  }

  private updateBrandingInvitations() {
    this.brandingInvitationService
      .getBrandingInvitations()
      .pipe(
        map((x) => x.map((bi) => InvitationTemplate.fromBrandingInvitation(bi)))
      )
      .subscribe((invitationTemplates) => {
        const allTemplates = [
          this.getGermanDefaultTemplate(),
          this.getEnglishDefaultTemplate(),
          ...invitationTemplates,
        ];
        this.selectedInvitationTemplate = allTemplates.find(
          (t) => t.id === this.selectedInvitationTemplateId
        );
        this.invitationTemplates.set(allTemplates);
        this.updateInvitationText(this.selectedInvitationTemplate.invitation);
        this.isLoading.set(false);
      });
  }

  private updateInvitationText(invitation: Invitation) {
    this.invitationText.set(`${invitation.text}\n${this.link}`);
    this.invitationDataService.setCurrentInvitationData(invitation);
  }

  onTemplateSelectionChanged(e: MatSelectChange) {
    this.selectedInvitationTemplate = this.invitationTemplates().find(
      (t) => t.id === e.value
    );
    localStorage.setItem(this.selectedInvitationTemplateIdStorageKey, e.value);
    this.updateInvitationText(this.selectedInvitationTemplate.invitation);
  }

  private readonly defaultTextEnglish = signal(
    this.getDefaultTextEnglish('Company')
  );

  private readonly defaultTextGerman = signal(
    this.getDefaultTextGerman('Firma')
  );

  private getEnglishDefaultTemplate() {
    return {
      id: 'default-template-english',
      title: $localize`Englisch (Standard)`,
      invitation: {
        language: InvitationLanguage.English,
        type: InvitationType.Default,
        method: InvitationMethod.Sms,
        text: this.defaultTextEnglish(),
      } as Invitation,
    } as InvitationTemplate;
  }

  private getGermanDefaultTemplate() {
    return {
      id: 'default-template-german',
      title: $localize`Deutsch (Standard)`,
      invitation: {
        language: InvitationLanguage.German,
        type: InvitationType.Default,
        method: InvitationMethod.Sms,
        text: this.defaultTextGerman(),
      } as Invitation,
    } as InvitationTemplate;
  }

  private readonly link = `<a class="chat-bubble-link" href="https://${this.realm}.blankmile.at/<id>">https://${this.realm}.blankmile.at/&lt;id&gt;</a>`;

  private getDefaultTextEnglish(companyDisplayName: string) {
    return `Hello!
You were invited by ${companyDisplayName} for a video call.
Link to appointment:`;
  }

  private getDefaultTextGerman(companyDisplayName: string) {
    return `Hallo!
Du wurdest von ${companyDisplayName} zu einer Videofernhilfe eingeladen.
Link zum Termin:`;
  }
}

export class InvitationTemplate {
  id: string;
  title: string;
  invitation: Invitation;

  static fromBrandingInvitation(bi: BrandingInvitation): InvitationTemplate {
    return {
      id: bi.id,
      title: bi.title,
      invitation: {
        language: InvitationLanguage.Unknown,
        type: InvitationType.Custom,
        method: InvitationMethod.Sms,
        text: bi.text,
      } as Invitation,
    } as InvitationTemplate;
  }
}

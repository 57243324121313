import { Component, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { CreateNewScheduledInquiryRequest } from '../../model/inquiry/request/create-new-scheduled-inquiry';
import { InquiryService } from '../../services/api/inquiry.service';
import { NavigationService } from '../../services/util/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-start-appointment-without-invitation',
  standalone: true,
  imports: [MatButton, MatIcon, MatProgressSpinner],
  templateUrl: './start-appointment-without-invitation.component.html',
  styleUrl: './start-appointment-without-invitation.component.scss',
  providers: [InquiryService],
})
export class StartAppointmentWithoutInvitationComponent {
  protected readonly isLoading$ = signal(false);

  private readonly inquiryService = inject(InquiryService);
  private readonly navigationService = inject(NavigationService);
  private readonly snackBar = inject(MatSnackBar);

  onStartAppointmentWithoutInvitationClicked() {
    this.isLoading$.set(true);
    const request = {
      startInstant: true,
    } as CreateNewScheduledInquiryRequest;
    this.inquiryService.createNewInquiry(request).subscribe({
      next: (inquiry) => {
        this.navigationService.navigateToVideoAppointment(
          inquiry.inquiryIdentifier,
          false
        );
      },
      error: () => {
        this.isLoading$.set(false);
        this.snackBar.open(
          $localize`Konnte keinen Termin erstellen, bitte versuche es später erneut.`
        );
      },
    });
  }
}

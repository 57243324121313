import {
  CreateLegalEntity as CreateLegalEntityRequest,
  CreateLegalEntityDto,
} from '../../../../api/gen';
import { LegalEntityAddress } from '../legal-entity-address';

export class CreateLegalEntity {
  legalEntity?: CreateLegalEntityData;

  static toRequest(r: CreateLegalEntity): CreateLegalEntityRequest {
    return {
      legalEntity: r.legalEntity
        ? CreateLegalEntityData.fromDto(r.legalEntity)
        : null,
    } as CreateLegalEntityRequest;
  }
}

export class CreateLegalEntityData {
  displayName?: string | null;
  logoUrl?: string | null;
  address?: LegalEntityAddress;

  static fromDto(dto: CreateLegalEntityDto): CreateLegalEntityData {
    return {
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
      address: dto.address ? LegalEntityAddress.fromDto(dto.address) : null,
    } as CreateLegalEntityData;
  }
}

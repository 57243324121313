import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotificationBarService } from './services/notification-bar.service';
import { isScreenWithoutMenus } from './menu/menu-visibility-helper';
import { ActivatedRoute } from '@angular/router';
import { BrandingDataService } from 'aidar-branding';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [BrandingDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  protected isLoading = signal(true);
  notificationBarVisible = false;
  menubarVisible = false;

  constructor(
    private readonly titleService: Title,
    protected readonly notificationBarService: NotificationBarService,
    private readonly route: ActivatedRoute,
    private readonly brandingService: BrandingDataService
  ) {
    this.titleService.setTitle($localize`blankmile`);
    this.brandingService.applyBranding().subscribe({
      next: (res) => {
        this.isLoading.set(false);
      },
      error: () => {
        this.isLoading.set(false);
      },
    });
  }

  activatedComponentChanged(event: any) {
    this.notificationBarVisible = !isScreenWithoutMenus(event);
    this.menubarVisible = !isScreenWithoutMenus(event);
    if (this.route.snapshot.queryParams['hideMenubar'] === 'true') {
      this.menubarVisible = false;
    }
  }
}

import { BrandingInvitationDto } from '../../../../api/gen';

export class BrandingInvitation {
  id?: string;
  createdAt?: string;
  title?: string | null;
  text?: string | null;
  isDefault?: boolean;
  deletedAt?: string | null;

  static fromDto(dto: BrandingInvitationDto): BrandingInvitation {
    return {
      id: dto.id,
      createdAt: dto.createdAt,
      title: dto.title,
      text: dto.text,
      isDefault: dto.isDefault,
      deletedAt: dto.deletedAt,
    } as BrandingInvitation;
  }
}

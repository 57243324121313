import { Injectable } from '@angular/core';
import { BrandingResult } from './branding-result';
import { BrandingColorDataService } from './color/branding-color-data-service';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BrandingDataService {
  constructor(
    private readonly brandingColorService: BrandingColorDataService
  ) {}

  public applyBranding(): Observable<BrandingResult> {
    return this.brandingColorService.applyBrandingColor().pipe(
      switchMap((res) => {
        return of({ success: res.success } as BrandingResult);
      })
    );
  }
}

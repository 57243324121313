import { LegalEntityService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { LegalEntity } from '../../model/legal-entity/legal-entity';
import { CreateLegalEntity } from '../../model/legal-entity/request/create-legal-entity';
import { UpdateLegalEntity } from '../../model/legal-entity/request/update-legal-entity';
import { Injectable } from '@angular/core';

@Injectable()
export class LegalEntityService {
  constructor(private readonly legalEntityService: Api) {}

  getLegalEntity(legalEntityId: string): Observable<LegalEntity> {
    return this.legalEntityService
      .companyLegalEntityLegalEntityIdGet(legalEntityId)
      .pipe(map((dto) => LegalEntity.fromDto(dto)));
  }

  getLegalEntities(): Observable<LegalEntity[]> {
    return this.legalEntityService
      .companyLegalEntityGet()
      .pipe(map((x) => x.map((dto) => LegalEntity.fromDto(dto))));
  }

  createLegalEntity(request: CreateLegalEntity): Observable<LegalEntity> {
    return this.legalEntityService
      .companyLegalEntityPost(CreateLegalEntity.toRequest(request))
      .pipe(map((dto) => LegalEntity.fromDto(dto)));
  }

  updateLegalEntity(
    legalEntityId: string,
    request: UpdateLegalEntity
  ): Observable<LegalEntity> {
    return this.legalEntityService
      .companyLegalEntityLegalEntityIdPatch(legalEntityId, request)
      .pipe(map((dto) => LegalEntity.fromDto(dto)));
  }

  deleteLegalEntity(legalEntityId: string): Observable<LegalEntity> {
    return this.legalEntityService
      .companyLegalEntityLegalEntityIdDelete(legalEntityId)
      .pipe(map((dto) => LegalEntity.fromDto(dto)));
  }
}

import { StaticTemplateDto } from 'projects/helper-client/src/api/gen';

export class StaticTemplate {
  id?: string;
  templateIdentifier?: string | null;
  cultureCode?: string | null;
  version?: number;
  displayName?: string | null;
  containerName?: string | null;
  fileName?: string | null;
  fileUrl?: string | null;
  tenantId?: string | null;

  static fromDto(dto: StaticTemplateDto): StaticTemplate {
    return {
      id: dto.id,
      templateIdentifier: dto.templateIdentifier,
      cultureCode: dto.cultureCode,
      version: dto.version,
      displayName: dto.displayName,
      containerName: dto.containerName,
      fileName: dto.fileName,
      fileUrl: dto.fileUrl,
      tenantId: dto.tenantId,
    } as StaticTemplate;
  }
}

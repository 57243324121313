import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatListItemIcon } from '@angular/material/list';
import { NgIf } from '@angular/common';
import { BeforeInstallPromptEvent } from '../before-install-prompt-event';

@Component({
  selector: 'app-install-pwa',
  standalone: true,
  imports: [MatButton, MatIcon, MatListItemIcon, NgIf],
  templateUrl: './install-pwa.component.html',
  styleUrl: './install-pwa.component.scss',
})
export class InstallPwaComponent {
  promptEvent: BeforeInstallPromptEvent;

  private readonly cdr = inject(ChangeDetectorRef);

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    this.promptEvent = e;
  }

  @HostListener('window:appinstalled', ['$event'])
  appInstalled() {
    // Needed as otherwise install btn is still visible after installation
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  public installPWA() {
    this.promptEvent?.prompt();
  }

  public shouldInstall(): boolean {
    return !this.isRunningInPWA() && !!this.promptEvent;
  }

  private isRunningInPWA(): boolean {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) =>
        window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }
}

import { ReportMailResultDto } from '../../../api/gen';

export class ReportMailResult {
  id?: string | null;
  eMail?: string | null;

  static fromDto(dto: ReportMailResultDto): ReportMailResult {
    return { id: dto.id, eMail: dto.eMail } as ReportMailResult;
  }
}

import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { Distance } from '../../../shared/distance-input/distance-input.component';
import { DistanceService } from '../../../services/api/distance.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ReportService } from '../../../services/api/report.service';
import { CreateInquirySummaryReportRequest } from '../../../model/report/request/create-inquiry-summary-report-request';
import { Report } from '../../../model/report/report';
import { StaticTemplateService } from '../../../services/api/static-template.service';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ProfileDataService } from '../../../services/data/profile-data.service';
import { ReportType } from '../../../model/report/report-type';
import { ReportOutputFormat } from '../../../model/report/report-output-format';
import { StaticTemplateIdentifier } from '../../../model/report/static-template-identifier';

@Component({
  selector: 'app-disconnected-dialog',
  templateUrl: './disconnected-dialog.component.html',
  styleUrls: ['./disconnected-dialog.component.scss'],
  providers: [ReportService, StaticTemplateService],
})
export class DisconnectedDialogComponent implements OnInit {
  protected selectedDistance: Distance;
  protected loading = true;
  protected employeeMail$: BehaviorSubject<string>;
  protected downloadingPdf$ = new BehaviorSubject(false);
  protected sendingPdf$ = new BehaviorSubject(false);

  constructor(
    private readonly reportService: ReportService,
    private readonly staticTemplateService: StaticTemplateService,
    private readonly distanceRepo: DistanceService,
    private readonly profileService: ProfileDataService,
    private readonly snackBar: MatSnackBar,
    private readonly renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public inquiryId: string,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  ngOnInit(): void {
    this.profileService.employeeInformation$.subscribe((employee) => {
      this.employeeMail$ = new BehaviorSubject<string>(employee.email);
    });

    if (this.inquiryId) {
      this.distanceRepo.getDistance(this.inquiryId).subscribe({
        next: (x) => {
          this.selectedDistance = x;
          this.loading = false;
        },
        error: console.error,
      });
    }
  }

  selectionChanged($event: Distance) {
    this.selectedDistance = $event;
    this.distanceRepo
      .setNewDistance(this.inquiryId, this.selectedDistance)
      .subscribe();
  }

  onDownloadInquirySummary() {
    this.downloadingPdf$.next(true);
    this.createReportFromTemplate(
      false,
      $localize`Report wird zum Herunterladen vorbereitet`,
      (report) => {
        this.downloadReport(report.blobUrl);
        this.snackBar.open(
          $localize`Report wurde erfolgreich heruntergeladen`,
          $localize`Ok`,
          { duration: 3000 }
        );
      }
    );
  }

  onSendInquirySummaryToEmployeeMail() {
    this.sendingPdf$.next(true);
    this.createReportFromTemplate(
      true,
      $localize`Report wird an Ihre Mail Addresse gesendet`,
      () => {
        this.snackBar.open(
          $localize`Report wurde erfolgreich an Ihre Mail Addresse gesendet`,
          $localize`Ok`,
          { duration: 3000 }
        );
      }
    );
  }

  downloadReport(downloadUrl: string) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadUrl);
    link.setAttribute('download', 'file.pdf');
    link.click();
    link.remove();
  }

  private createReportFromTemplate(
    sendMail: boolean,
    creationMessage: string,
    success: (report: Report) => void
  ) {
    const creatingReportSnackBar = this.snackBar.open(creationMessage);

    this.staticTemplateService
      .getStaticTemplateByIdentifierAndCulture(
        StaticTemplateIdentifier.InquirySummaryIdentifier,
        this.locale
      )
      .subscribe((template) => {
        this.createReport(
          sendMail,
          creatingReportSnackBar,
          template.templateIdentifier,
          success
        );
      });
  }

  private createReport(
    sendMail: boolean,
    snackbar: MatSnackBarRef<TextOnlySnackBar>,
    templateIdentifier: string,
    success: (report: Report) => void
  ) {
    const request = {
      inquiryIdentifier: this.inquiryId,
      configuration: {
        includeHeader: true,
        includeInquiryInformation: true,
        includeAttachments: true,
        includeTimeline: false,
        includeNotes: true,
        templateIdentifier: templateIdentifier,
        type: ReportType.InquirySummary,
        outputFormat: ReportOutputFormat.Pdf,
      },
      cultureCode: this.locale,
      emailRecipient: sendMail ? this.employeeMail$.value : null,
    } as CreateInquirySummaryReportRequest;
    this.reportService
      .createInquirySummaryReport(request)
      .subscribe((report) => {
        success(report);
        snackbar.dismiss();
      });
  }
}

import { CreateBrandingInvitation } from '../../../../../api/gen';

export class CreateBrandingInvitationRequest {
  title?: string | null;
  text?: string | null;
  isDefault?: boolean;

  static toRequest(
    request: CreateBrandingInvitationRequest
  ): CreateBrandingInvitation {
    return {
      title: request.title,
      text: request.text,
      isDefault: request.isDefault,
    } as CreateBrandingInvitation;
  }
}
